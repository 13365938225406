import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

import { AngularFirestore } from '@angular/fire/firestore';

import { Device } from '../interfaces/device';
import { AuthService } from './auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private browser: boolean = true;
    public token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor (
        public auth: AuthService,
        public platform: Platform,
        public storage: Storage,
        public afs: AngularFirestore
    ) {
        if (this.platform.is("cordova") && (this.platform.is("android") || this.platform.is("ios"))) {
            this.browser = false;
        }
    }

    public isBrowser(): boolean {
        return this.browser;
    }

    public isNative(): boolean {
        return !this.browser;
    }

    public isAndroid(): boolean {
        return !this.browser && this.platform.is("android");
    }

    public isIOS(): boolean {
        return !this.browser && this.platform.is("ios");
    }

    public get localFCMDevice(): Promise<Device> {
        return this.storage.get("device");
    }

    public saveFCMToken(token) {
        if (!token) {
            console.log("[device] no [fcm] instance ID token available. Please request permission to generate one.");
            return;
        }
        
        // make sure we have the user id
        this.auth.trigger.subscribe(user => {
            if (user) {
                const devicesRef = this.afs.collection(`users/${user.uid}/devices`)

                let defaultData = {
                    active: true,// enabled by default!
                    token: token,
                    platform: (this.isBrowser() ? "browser" : (this.isAndroid() ? "android" : (this.isIOS() ? "ios" : "undefined"))),
                    userAgent: (navigator && navigator.userAgent) ? navigator.userAgent : "",
                    lastUpdate: Date.now(),
                }

                // is this device already registered?
                this.storage.get("device").then((device) => {
                    if (!device) {
                        device = {
                            token: null
                        }
                    }

                    // delete old device token if we do have a new one
                    if (device.token !== token) {
                        if (device.token !== null) {
                            devicesRef.doc(device.token).get().subscribe(doc => {
                                console.groupCollapsed("[device] dealing with existing [fcm] token (expand to see details)")
                                if (doc.exists) {
                                    var oldSettings = doc.data()
                                    if (typeof oldSettings.active == "boolean") {
                                        // copy old settings to new "default" dataset
                                        defaultData = Object.assign(defaultData, {active: oldSettings.active})
                                    }
                                    console.log("[device] deleting old [fcm] token in firestore!")
                                    devicesRef.doc(device.token).delete()
                                }
 
                                console.log("[device] saving updated [fcm] token in firestore!", defaultData)
                                devicesRef.doc(token).set(defaultData)
                            })
                        } else {
                            console.log("[device] saving new [fcm] token in firestore!", defaultData)
                            devicesRef.doc(token).set(defaultData)
                        }
                        console.groupEnd()
                    } else {
                        // update the time but never the active value!
                        delete defaultData.active;
                        devicesRef.doc(token).update(defaultData).catch((e) => {
                            // could not update old token, creating new one instead (disabled)
                            console.log("[device] [fcm] unchanged token could not be updated, created new one instead")
                            devicesRef.doc(token).set(Object.assign(defaultData, {active: true}))
                        })

                        console.groupCollapsed("[device] [fcm] token is unchanged (expand to see details)")
                        console.log("[device] -- active value was not updated but is still set in firestore")
                        console.log(defaultData)
                        console.groupEnd()
                    }

                    // always update local information
                    this.storage.set("device", {token})
                    this.token.next(token)
                })
            }
        })
    }
}