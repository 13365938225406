import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { map } from "rxjs/operators";

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { AuthService } from './auth';
import { Device } from '../interfaces/device';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DevicesService {
    private ref: AngularFirestoreCollection<Device>;
    public devices: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);

    constructor (
        public auth: AuthService,
        public storage: Storage,
        public afs: AngularFirestore
    ) {
        this.auth.trigger.subscribe(user => {
            this.ref = this.afs.collection(`users/${user.uid}/devices`)
            this.ref.snapshotChanges().pipe(
                map(devices => {
                    // map information including id
                    return devices.map(device => {
                        const data = device.payload.doc.data() as Device;
                        const id = device.payload.doc.id;
                        return { id, ...data };
                    })
                })
            ).subscribe(devices => {
                // push changes to this.devices
                this.devices.next(devices)
            })
        })
    }

    public get all (): BehaviorSubject<Device[]> {
        return this.devices;
    }

    public update (device: Device, update): void {
        this.ref.doc(device.id).update(update)
    }
}