import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'},
    { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule'},
    { path: 'stream', loadChildren: './pages/stream/stream.module#StreamPageModule' },
    { path: 'action', loadChildren: './pages/action/action.module#ActionPageModule' },
    { path: 'preferences', loadChildren: './pages/preferences/preferences.module#PreferencesPageModule'},
    { path: '', redirectTo: 'stream', pathMatch: 'full' },
    { path: '**', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
