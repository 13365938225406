import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFirestore } from "@angular/fire/firestore";

import { Observable } from 'rxjs';

import { User } from "../interfaces/user";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor (
        private db: AngularFirestore,
        public sanitization: DomSanitizer
    ) {}

    // prepare user object
    public prepareImg (img: string): any {
        return this.sanitization.bypassSecurityTrustStyle("url('img/"+ img +"')");
    }

    get any (): Observable<User[]> {
        return this.db.collection<User>("/users").valueChanges();
    }

    get any_snapshot (): Observable<any[]> {
        return this.db.collection("/users").snapshotChanges();
    }

    // returns user by id
    public byID (user_id: string): Observable<User> {
        return this.db.doc<User>("/users/"+ user_id).valueChanges();
    }

    /*
    // returns friendlist of user_key
    public friendsOf (user_key: string): BehaviorSubject<FirebaseObjectObservable<any>[]> {
        let re = new BehaviorSubject<FirebaseObjectObservable<any>[]>([]);

        this.users.subscribe(users => {
            this.db.list("/friends").subscribe(friends_list => {
                let friends = [];

                for (let user of users) {
                    for (let combination of friends_list) {
                        if ((combination.user1 == user_key && combination.user2 == user.$key) || (combination.user1 == user.$key && combination.user2 == user_key)) {
                            friends.push(user);
                        }
                    }
                }

                // push to behaviorsubject
                re.next(friends);
            })
        })


        return re;
    }

    // get friendship between two users
    public friendship (user1_key: string, user2_key: string): any {
        let re = new BehaviorSubject<any>(false);

        if (user1_key != user2_key) {
            // get all friend combinations
            this.db.list("/friends").subscribe(friends_list => {
                let found = false;
                for (let combination of friends_list) {
                    if ((combination.user1 == user1_key || combination.user2 == user1_key) && (combination.user1 == user2_key || combination.user2 == user2_key)) {
                        re.next(combination)
                        found = true;
                    }
                }
                if (!found) {
                    re.next(false)
                }
            })
        } else {
            re.next(true);
        }

        return re;
    }
    */
}