import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { UsersService } from './users';

import { BehaviorSubject, Observable } from 'rxjs';

import { User } from "../interfaces/User";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private auth: any = false;
    private error: any = null;

    _user: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor (
        private afAuth: AngularFireAuth,
        public usersService: UsersService
    ) {
        this.afAuth.authState.subscribe(
            firebaseuser => {
                console.groupCollapsed("[auth] subscription (expand to see details)")
                console.log(firebaseuser);
                console.groupEnd()
                
                this.auth = firebaseuser;
                
                if (this.authenticated) {
                    // get additional information from our user service
                    this.usersService.byID(this.auth.uid).subscribe((user) => {
                        if (user) {
                            this._user.next(Object.assign(user, firebaseuser))
                        } else {
                            console.log("user could not be found by user service")
                        }
                    }, e => {
                        return false;
                    })
                }
            },
            error => this.error = error
        );
    }

    get trigger(): Observable<firebase.User> {
        return this.afAuth.authState;
    }

    // logged in?
    get authenticated(): boolean {
        return (this.auth !== null);
    }

    public login (email: string, password: string): any {
        console.log("attempting to log in")
        this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then(success => {
            console.log("successfull logged in")
        })
        .catch(error => {
            console.log("failed to login: ", error)
        })
    }

    // performs logout
    public logout () {
        console.log("logging out...");
        this.afAuth.auth.signOut();
    }

    get user (): BehaviorSubject<User> {
        return this._user;
    }
}