import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AngularFireMessaging } from "@angular/fire/messaging";
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from "./auth";
import { DeviceService } from "./device";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FCMService {
    private messaging: any = null;
    private unsubscribeOnTokenRefresh: any = () => {};
    private messages: BehaviorSubject<any> = new BehaviorSubject(null);
    public warning: boolean = true;
  
    constructor (
        private auth: AuthService,
        private alertCtrl: AlertController,
        public afm: AngularFireMessaging,
        public afs: AngularFirestore,
        public deviceService: DeviceService
    ) {
        this.auth.trigger.subscribe(user => {
            if (user) {
                if (navigator.serviceWorker) {
                    this.warning = false;

                    // register service worker for background notifications
                    navigator.serviceWorker.register("firebase-messaging-sw.js").then((registration) => {
                        /* could be used as message bus
                        if (navigator.serviceWorker.controller) {
                            navigator.serviceWorker.controller.postMessage({"url": location.origin})
                        }
                        */

                        // set up notification messaging
                        this.afm.messaging.subscribe(messaging => {            
                            this.messaging = messaging;

                            // tricked a bit to access "useServiceWorker"
                            try {
                                this.messaging.useServiceWorker(registration)
                            } catch (e) {
                                // should only be called once, I know
                            }
                            
                            // small workaround below:
                            messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);

                            this.enableNotifications()
                        })
                    })
                }
            }
        })
    }

    public async enableNotifications () {
        if (this.messaging === null) {
            return;
        }

        this.deviceService.localFCMDevice.then(device => {
            if (device) {
                console.log("[fcm] local stored token", device)
                this.deviceService.saveFCMToken(device.token)
            }
        })

        console.log("[fcm] if not already happened: enabling notifications / requesting permission...");
        /*
        if (this.deviceService.isNative()) {
            let token;

            if (this.deviceService.isAndroid()) {
                token = await this.firebaseNative.getToken()
            } else if (this.deviceService.isIOS()) {
                token = await this.firebaseNative.getToken();
                await this.firebaseNative.grantPermission();
            }

            console.log("[fcm] native permission granted");

            // delegate messages to custom observable
            this.firebaseNative.onNotificationOpen().subscribe(msg => {
                this.messages.next(msg)
            })

            // token might change - we need to listen for changes to it and update it
            this.setupOnTokenRefresh();

            this.deviceService.saveFCMToken(token)

        } else {
            */
        // set up vapid
        try {
            this.messaging.usePublicVapidKey(environment.vapidKey)
            console.log("[fcm] public key inititated")
        } catch (e) {
            // only once, I know
        }

        /*
        this.afm.requestToken.subscribe(
            (token) => {
                console.log("[fcm] pwa permission granted");
                this.deviceService.saveFCMToken(token)

            },
            (e) => {
                console.log("[fcm] pwa token error", e)
            }
        )
        */
        Notification.requestPermission().then((result) => {
            var permission = false;
            if (result == "denied") {
                console.log("[fcm] permission request was denied")
            }

            if (result == "default") {
                console.log("[fcm] permission request was dismissed")
            }

            if (result == "granted") {
                console.log("[fcm] permission granted")
                permission = true;
            }

            if (!permission) {
                let confirm = this.alertCtrl.create({
                    header: "Allow notifications",
                    subHeader: "Please grant the permission to send you notifications via your browser settings. You can customize them later on!",
                    buttons: [
                        {
                            text: "Ok",
                            role: "cancel",
                            cssClass: "primary",
                            handler: () => {
                                this.enableNotifications()
                            }
                        },
                    ]
                })
                confirm.then((alert) => alert.present())
            } else {
                this.afm.getToken.subscribe((token) => {
                    console.log("[fcm] token via afm.getToken")
                    this.deviceService.saveFCMToken(token)
                })
            }
        });
        
        // token might change in future
        this.setupOnTokenRefresh()

        // delegate messages to custom observable
        this.afm.messages.subscribe(msg => {
            this.messages.next(msg)
        })        
    }
    
    public disableNotifications() {
        // delete token: https://github.com/angular/angularfire2/blob/master/docs/messaging/messaging.md
        this.unsubscribeOnTokenRefresh();
        this.unsubscribeOnTokenRefresh = () => {};
        this.deviceService.saveFCMToken("")
    }
    
    private setupOnTokenRefresh(): void {
        // token refresh
        /*
        if (this.deviceService.isNative()) {
            this.unsubscribeOnTokenRefresh = this.firebaseNative.onTokenRefresh().subscribe(token => {
                this.deviceService.saveFCMToken(token)
                console.log("[fcm] token refreshed")
            })
        } else {
            */
        this.unsubscribeOnTokenRefresh = this.afm.tokenChanges.subscribe(token => {
            this.deviceService.saveFCMToken(token)
            console.log("[fcm] token changed")
        })
    }

    // listen to incoming FCM messages
    public get notifications () {
        return this.messages;
    }
}