import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators";

import { AuthService } from "./auth";

import { StreamItem } from '../interfaces/stream';
import { User } from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private ref: AngularFirestoreCollection<StreamItem>
    private streamItems: BehaviorSubject<StreamItem[]> = new BehaviorSubject<StreamItem[]>([]);

    constructor (
        private afs: AngularFirestore,
        private auth: AuthService
    ) {
        this.auth.trigger.subscribe((user: User) => {
            if (user) {
                this.ref = this.afs.collection<StreamItem>(`users/${user.uid}/notifications`);
                this.ref.snapshotChanges().pipe(
                    map(items => {
                        return items.map(item => {
                            const data = item.payload.doc.data() as StreamItem;
                            const id = item.payload.doc.id;
                            return { id, ...data };
                        })
                    })
                ).subscribe(items => {
                    this.streamItems.next(items)
                })
            }
        })
    }

    // returns notifications for current user
    public get stream (): BehaviorSubject<StreamItem[]> {
        return this.streamItems;
    }

    public update (item: StreamItem, update): void {
        this.ref.doc(item.id).update(update)
    }

    public get reference (): AngularFirestoreCollection<StreamItem> {
        return this.ref;
    }

    public byId (id: string): BehaviorSubject<StreamItem> {
        let item: BehaviorSubject<StreamItem> = new BehaviorSubject<StreamItem>(null);

        this.streamItems.subscribe((items) => {
            item.next(items.find(i => i.id === id))
        })

        return item;
    }
}