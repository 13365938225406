import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { filter } from 'rxjs/operators';

import { Platform, ToastController, MenuController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth';
import { FCMService } from './services/fcm';
import { DeviceService } from './services/device';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public self: any;

    constructor(
        private auth: AuthService,
        private router: Router,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private swUpdate: SwUpdate,
        public device: DeviceService,
        public fcm: FCMService,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public menuCtrl: MenuController
    ) {
        this.initializeApp();

        // close menu on navigation end
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.menuCtrl.close()
        })
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                let confirm = this.alertCtrl.create({
                    header: "Update available!",
                    subHeader: "By reloading this site, you will get the latest improvements. Load new version?",
                    buttons: [
                        {
                            text: "No",
                            role: "cancel",
                            cssClass: "secondary",
                            handler: () => {}
                        },
                        {
                            text: "Yes",
                            cssClass: "primary",
                            handler: () => {
                                location.reload()
                            }
                        },
                    ]
                })
            })
        }        
    }

    initializeApp() {
        this.platform.ready().then(() => {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            // firebase auth
            this.auth.trigger.subscribe(auth => {
                if (auth) {
                    this.auth.user.subscribe(user => {
                        this.self = user;
                    })

                    // firebase cloud messaging
                    this.fcm.notifications.subscribe(msg => {
                        if (msg !== null) {
                            // prepare the message
                            if (msg.data) {
                                if (msg.data.notification) {
                                    msg.data.notification = JSON.parse(msg.data.notification)
                                }
                                if (msg.data.payload) {
                                    msg.data.payload = JSON.parse(msg.data.payload)
                                }
                            }

                            if (msg.wasTapped) {
                                // background
                                msg.body = `[bg] ${msg.data.notification.title}`;
                            } else {
                                // foreground
                                msg.body = `${msg.data.notification.title}`;
                            }
                            let toast = this.toastCtrl.create({
                                message: msg.data.notification.description,
                                duration: 3000
                            }).then(toast => toast.present())
                        }
                    })

                } else {
                    if (this.router.url != "/login" && this.router.url != "/logout") {
                        this.router.navigate(["/login"])
                    }
                }
            })
        });
    }

    logout () {
        this.auth.logout()
    }
}
